
import {
  Vue,
  Component,
  Prop,
  PropSync,
} from 'vue-property-decorator';
import {
  Property,
  Value,
} from '@/types/vjsf';

// Lazy loading imports
const Tooltip = () => import(
  /* webpackChunkName: "form-tooltip" */
  /* webpackPrefetch: false */
  '@/components/Tooltip/Tooltip.vue'
);

@Component({
  name: 'RadioGroupField',
  components: {
    Tooltip,
  },
})
export default class RadioGroupField extends Vue {
  @PropSync('value', {
    required: true,
    default: null,
  })
  private selection!: Value;

  @Prop({ required: true })
  private readonly property!: Property;

  @Prop({ required: true })
  private readonly serverLocale!: string;

  @Prop({ required: true })
  private readonly required!: boolean;

  setValue(value: Value): void {
    this.selection = value;
  }
}
